@font-face {
  font-family: 'CoreSans';
  src: local('CoreSans'), url(../public/fonts/CoreSans/CoreSansC-45Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body,
#root {
  height: 100%;
  background-color: black;
}

#root>* {
  min-height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: CoreSans, sans-serif;
}